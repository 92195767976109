<template>
  <v-app>
    <v-app-bar :height="appBarHeigth" app color="primary" elevate-on-scroll>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.xsOnly"
        class="mr-2"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-tabs
        centered
        class="hidden-sm-and-down"
        slider-color="primary2"
        color="primary2"
      >
        <v-tab v-for="item in items" :key="item.title" :to="item.url">{{
          $t(item.title)
        }}</v-tab>
      </v-tabs>

      <SwitchLanguage />
    </v-app-bar>
    <v-navigation-drawer
      app
      v-model="drawer"
      temporary
      color="primary2"
      v-if="$vuetify.breakpoint.xsOnly && drawer"
    >
      <v-list dense nav class="py-5">
        <v-list-item two-line :class="miniVariant && 'px-0'">
          <v-list-item-content>
            <div class="d-flex align-center">
              <v-img
                alt="ClassRoom Libertadores"
                class="shrink ml-4"
                contain
                src=""
                transition="scale-transition"
                width="200"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <div class="py-5">
          <div v-for="item in items" :key="item.title">
            <v-list-group
              v-if="item.childrens.length > 0"
              active-class="active-class"
              link
              :prepend-icon="item.icon"
            >
              <template v-slot:activator>
                <v-list-item-title class="v-list-item__title">{{
                  $t(item.title)
                }}</v-list-item-title>
              </template>
              <div v-for="child in item.childrens" :key="child.title">
                <v-list-item
                  class="ml-10 v-list-item__title"
                  dark
                  :to="child.url"
                >
                  <v-list-item-icon class="active-class">
                    <v-icon>{{ child.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    class="active-class"
                    v-text="$t(child.title)"
                  ></v-list-item-title>
                </v-list-item>
              </div>
            </v-list-group>
            <v-list-item v-if="item.childrens.length <= 0" :to="item.url">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer dark padless height="350">
      <v-card
        flat
        height="100%"
        width="100%"
        class="primary2 px-12 white--text "
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="3"
              md="2"
              v-for="(section, index) in footerSections"
              :key="index"
            >
              <h2 class="section-footer-title">{{ $t(section.title) }}</h2>
              <div class="mb-4 mt-2 div-divider"></div>

              <div
                v-for="(link, index) in section.links"
                
                :key="index"
                class="my-1 body-1 font-weight-bold"
              >
                <router-link :to="$t(link.url)"  class="text-decoration-none primary--text">{{
                  $t(link.name)
                }}</router-link>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import SwitchLanguage from "@/components/BasicComponents/SwitchLanguage";
export default {
  name: "App",

  components: {
    SwitchLanguage,
  },

  data() {
    return {
      drawer: false,
      expandOnHover: false,
      miniVariant: true,
      items: [
        {
          title: "menu.dashboard",
          icon: "mdi-home",
          url: "/home",
          childrens: [],
        },
        {
          title: "menu.orenda",
          icon: "mdi-format-list-checkbox",
          url: "/objectives",
          childrens: [],
          open: false,
        },
        {
          title: "menu.learning",
          icon: "mdi-domain",
          url: "/corporations",
          childrens: [],
          open: false,
        },
        {
          title: "menu.blog",
          icon: "mdi-account-group",
          url: "/team",
          childrens: [],
          open: false,
        },
        {
          title: "menu.gallery",
          icon: "mdi-calendar-check",
          url: "/course",
          childrens: [],
          open: false,
        },
        {
          title: "menu.contact",
          icon: "mdi-mailbox",
          url: "/contactus",
          childrens: [],
          open: false,
        },
      ],
      items_admin: [],
      color: "primary",
      colors: ["primary", "blue", "success", "red", "teal"],
      permanent: true,
      drawerOpen: false,
      appBarHeigth: 85,
      footerSections: [
        {
          title: "footer.sections.products.title",
          links: [
            {
              name: "footer.sections.products.childs.substratum.title",
              url: "footer.sections.products.childs.substratum.url",
            },
            {
              name: "footer.sections.products.childs.tools.title",
              url: "footer.sections.products.childs.tools.url",
            },
            {
              name: "footer.sections.products.childs.trees.title",
              url: "footer.sections.products.childs.trees.url",
            },
            {
              name: "footer.sections.products.childs.pot.title",
              url: "footer.sections.products.childs.pot.url",
            },
            {
              name: "footer.sections.products.childs.gift.title",
              url: "footer.sections.products.childs.gift.url",
            },
          ],
        },
        {
          title: "footer.sections.learning.title",
          links: [
            {
              name: "footer.sections.learning.childs.oneday.title",
              url: "footer.sections.learning.childs.oneday.url",
            },
            {
              name: "footer.sections.learning.childs.basic.title",
              url: "footer.sections.learning.childs.basic.url",
            },
            {
              name: "footer.sections.learning.childs.tools.title",
              url: "footer.sections.learning.childs.tools.url",
            },
            {
              name: "footer.sections.learning.childs.corporativeoneday.title",
              url: "footer.sections.learning.childs.corporativeoneday.url",
            },
          ],
        },
        {
          title: "footer-products-title",
          links: [
            {
              name: "tree-link-name",
              url: "tree-link-url",
            },
            {
              name: "pot-link-name",
              url: "pot-link-url",
            },
            {
              name: "tools-link-name",
              url: "tools-link-url",
            },
          ],
        },
        {
          title: "footer-products-title",
          links: [
            {
              name: "tree-link-name",
              url: "tree-link-url",
            },
            {
              name: "pot-link-name",
              url: "pot-link-url",
            },
            {
              name: "tools-link-name",
              url: "tools-link-url",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style lang="scss">
@import "@/scss/variables.scss";
@import "@/scss/app/custom.scss";
@import "@/scss/app/app.scss";
</style>
