<template>
  <div>
    <section class="mb-12">
      <v-row justify="center">
        <v-col cols="10">
          <v-carousel cycle height="600">
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
              <div class="carousel-shadow px-12">
                <div class="carousel-shadow-title">{{ item.title }}</div>
                <div class="carousel-shadow-text">{{ item.text }}</div>
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </section>

    <section ref="whatIs" class="my-12">
      <v-container>
        <v-row>
          <v-col cols="12" class="px-10 pt-2">
            <h1
              class="text-center primary--text my-10"
              v-html="$t('home-what-is')"
            ></h1>
            <p class="text-center" v-html="$t('home-what-is-pharagraph')"></p
          ></v-col>
        </v-row>
      </v-container>
    </section>
    <section class="section-green my-12">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" class="px-10 pt-2">
            <h1
              class="text-center white--text my-10"
              v-html="$t('home-blog-last')"
            ></h1>
            <p
              class="text-center white--text"
              v-html="$t('home-blog-last-pharagraph')"
            ></p>
            <v-row>
              <v-col cols="4" v-for="article in articles" :key="article.id">
                <v-card class="mx-auto white" max-width="434" tile>
                  <v-img height="100%" :src="article.image">
                    <v-row align="end" class="fill-height">
                      <!-- <v-col align-self="start" class="pl-5" cols="12">
                        <v-avatar color="grey" size="100">
                          <v-img :src="article.profilePicture"></v-img>
                        </v-avatar>
                      </v-col> -->
                      <v-col class="py-0">
                        <v-list-item color="rgba(0, 0, 0, .4)" dark>
                          <v-list-item-content>
                            <v-list-item-title class="title">
                              {{ article.title }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              v-html="$t(article.date)"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-img>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="showArticle(article)" icon>
                      <v-icon>mdi-text</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card></v-col
              >
              <v-col v-if="visibleArticle" cols="12">
                <transition name="fade" mode="out-in" appear>
                  <v-card color="primary" class="px-6">
                    <v-card-actions class="ml-4">
                      <!-- <v-avatar color="grey" size="100">
                        <v-img :src="ArticleToShow.profilePicture"></v-img>
                      </v-avatar> -->
                      <v-card-title class="white--text stronged--text">
                        {{ articleToShow.title }}</v-card-title
                      >
                      <v-btn color="white" @click="closeArticle()" icon>
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-actions>
                    <v-card-text
                      class="mx-2 white--text text-justify stronged--text"
                      >{{ articleToShow.text }}</v-card-text
                    >
                  </v-card>
                </transition>
              </v-col>
            </v-row>            
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section>
      <v-container>
        <!-- <v-row justify="center">
          <h1
            class="text-center primary--text my-10"
            v-html="$t('home-how-work-title')"
          ></h1>
        </v-row>
        <v-row justify="center">
          <v-col cols="8">
            <p class="text-center" v-html="$t('home-how-work-pharagraph')"></p>
          </v-col>
        </v-row>
        <v-row justify="center" class="pb-10">
          <v-col cols="6">
            <v-expansion-panels inset>
              <v-expansion-panel v-for="(item, i) in howWorkSlides" :key="i">
                <v-expansion-panel-header
                  color="primary"
                  class="text-center white--text strong--text obscure-backgroud"
                  >{{ $t(item.title) }}</v-expansion-panel-header
                >
                <v-expansion-panel-content class="strong--text py-3">
                  {{ $t(item.description) }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row> -->
      </v-container>
    </section>

    <section>
      <v-container>
        <v-row>
          <v-col
            v-for="(university, i) in universities"
            :key="i"
            cols="4"
            class="px-10 pt-10"
            ><v-img
              class="shrink ml-4  img-universities"
              :src="university.logo"
              contain
              transition="scale-transition"
              height="100"
              @click="OpenModal(university)"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="800"
      v-model="universityShow"
    >
      <v-card>
        <v-toolbar
          class="text-h5 px-8 py-5"
          color="primary"
          dark
          v-html="$t(currentUniversity.name)"
        ></v-toolbar>
        <v-card-text>
          <v-img
            class="mt-10 mx-5"
            contain
            :src="currentUniversity.logo"
            transition="scale-transition"
            height="100"
            @click="OpenModal(currentUniversity)"
          />
          <div
            class=" py-5 px-5"
            v-html="$t(currentUniversity.description)"
          ></div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="universityShow = false">{{
            $t("close-button")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  data() {
    return {
      universityShow: false,
      currentUniversity: {},
      howWorkSlides: [
        {
          title: "home-how-work-icebreaker-title",
          description: "home-how-work-icebreaker-pharagraph",
        },
        {
          title: "home-how-work-develop-collaborative-activities-title",
          description:
            "home-how-work-develop-collaborative-activities-pharagraph",
        },
        {
          title: "home-how-work-case-study-title",
          description: "home-how-work-case-study-pharagraph",
        },
        {
          title: "home-how-work-final-reflection-title",
          description: "home-how-work-final-reflection-pharagraph",
        },
      ],
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
      whyParticipate: [
        {
          icon: "mdi-wallet-travel",
          description: "home-why-participate-1",
        },
        {
          icon: "mdi-book-open",
          description: "home-why-participate-2",
        },
        {
          icon: "mdi-translate",
          description: "home-why-participate-3",
        },
        {
          icon: "mdi-web",
          description: "home-why-participate-4",
        },
      ],
      articles: [
        {
          title: "Articulo 1 ",
          date: "31/01/2020",
          image: require("@/assets/images/cities/saopablo.jpg"),
          text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also",
          show: false,
        },
        {
          title: "Articulo 2",
          date: "20/01/2021",
          image: require("@/assets/images/cities/barranquilla.jpg"),
          text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also",
          show: false,
        },
        {
          title: "Articulo 3",
          date: "31/08/2021",
          image: require("@/assets/images/cities/bogota.jpg"),
          text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also",
          show: false,
        },
      ],
      universities: [],

      items: [
        {
          title: "Titulo 1",
          text:
            '"No hagas que tus árboles parezcan bonsai: haz que tus bonsais parezcan árboles”',
          src: require("@/assets/images/slider/IMG_6417.jpg"),
          autor: "John Yoshio Naka",
        },
        {
          title: "Titulo 2",
          text:
            '"Lo que me gusta del bonsai es que tiene un comienzo pero no un final. Una yema hoy es una rama mañana, es como la búsqueda del final del arcoiris. No hay límites en el Bonsai”',
          src: require("@/assets/images/slider/IMG_6442.jpg"),
          autor: "J. Y. Naka",
        },
        {
          title: "Titulo 3",
          text:
            '"La verdadera belleza sólo puede ser descubierta mentalmente por quién completa lo incompleto”',
          src: require("@/assets/images/slider/IMG_6454.jpg"),
          autor: "Kakuzo Okakura",
        },
        {
          title: "Titulo 4",
          text:
            '". . . el bonsai es un arte y mucho del proceso de formación sigue siendo un misterio, eludiendo la palabra escrita ”',
          src: require("@/assets/images/slider/IMG_6460.jpg"),
          autor: "Nick Lenz",
        },
        {
          title: "Titulo 5",
          text:
            '"Un Bonsái, obra de arte viviente, es una creación artística lograda con sentido estético y destreza, que busca extraer de la belleza natural de una planta todo su esplendor”',
          src: require("@/assets/images/slider/IMG_6613.jpg"),
          autor: "Kenyi Murata",
        },
        {
          title: "Titulo 6",
          text:
            '"recibimos, por parte del bonsái, paz mental y salud. Además, aprendemos generosidad y paciencia. Así que, el bonsái se ve como un símbolo de paz que une a todas las razas y nacionalidades',
          src: require("@/assets/images/slider/IMG_6669.jpg"),
          autor: "Saburo Kato",
        },
      ],
      articleToShow: {},
      visibleArticle: false,
    };
  },
  methods: {
    OpenModal(university) {
      this.universityShow = true;
      this.currentUniversity = university;
    },
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    showArticle(article) {
      if (article == this.articleToShow) {
        this.closeArticle();
      } else {
        this.articleToShow = article;
        this.visibleArticle = true;
      }
    },
    closeArticle() {
      this.articleToShow = {};
      this.visibleArticle = false;
    },
  },
};
</script>
