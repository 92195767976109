import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Objectives from '../views/Objectives.vue'
import Team from '../views/Team.vue'
import Corporations from '../views/Corporations.vue'
import Course from '../views/Course.vue'
import ContactUs from '../views/ContactUs.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    component: Home
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/objectives',
    name: 'Objectives',
    component: Objectives
  },
  {
    path: '/corporations',
    name: 'Corporations',
    component: Corporations
  },
  {
    path: '/team',
    name: 'Team',
    component: Team
  },
  {
    path: '/course',
    name: 'Course',
    component: Course
  },
  {
    path: '/contactus',
    name: 'ContactUs',
    component: ContactUs
  }
]

const router = new VueRouter({
  routes
})

export default router
