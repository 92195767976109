<template>
  <v-container>
    <v-row justify="center">
      <h1 class="page-title my-10">{{ $t("contact-menu") }}</h1>
    </v-row>
    <section>
      <v-card class="px-10 py-10 mx-10 my-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            outline
            v-model="name"
            :counter="10"
            :rules="nameRules"
            label="Nombre"
            required
          ></v-text-field>

          <v-text-field
            outline
            v-model="email"
            :rules="emailRules"
            label="Correo"
            required
          ></v-text-field>

          <v-textarea
            outline
            v-model="comentario"
            label="Comentario"
            required
          ></v-textarea>

          <v-btn
            :disabled="!valid"
            color="primary"
            class="mr-4"
            @click="validate"
          >
            Enviar
          </v-btn>

          <v-btn color="primary" outlined class="mr-4" @click="reset">
            Cancel
          </v-btn>
        </v-form>
      </v-card>
    </section>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
